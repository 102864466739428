<template>
	<div id="school">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学校设置</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content u-f">
				<div class="school-info u-f4">
					<div class="u-f-right info-title">
						<el-button type="primary" size="small" style="width: 100px;" @click="editVisible = true">编辑</el-button>
					</div>
					<div class="infos">
						<div class="u-f-justify u-f-column">
							<el-avatar shape="circle" :size="150" :src="imageUrl"></el-avatar>
							<div style="margin-left: 10px;" class="u-f-justify u-f-column">
								<div class="school-name">{{info.school_name}}</div>
								<div class="school-type">{{info.school_nature=="1"?'独立学校':'集团化学校'}}</div>
							</div>
						</div>
						<div class="school-brief">
							{{info.school_intro}}
						</div>
					</div>
				</div>
				
			</div>
			<!-- 编辑 -->
			<el-dialog title="编辑" :visible.sync="editVisible" width="40%" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							校名
						</div>
						<el-input v-model="schoolName" placeholder="请输入校区名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							性质
						</div>
						<el-radio-group v-model="school_nature">
							<el-radio-button label="1">独立学校</el-radio-button>
							<el-radio-button label="2">集团化学校</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							简介
						</div>
						<el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="school_intro" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							校徽
						</div>
						<el-upload
							class="avatar-uploader"
							action="http://hlgzx.zhiyunapi.com/v1/school_api/common/upload"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
							:headers="headers"
						>
							<img v-if="imageUrl" :src="imageUrl" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editVisible = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="edit_school">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加校区 -->
			<el-dialog title="添加校区" :visible.sync="addVisible" width="26%" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal" style="margin-left: 15px;">
							添加
						</div>
						<el-button @click="addSchools">添加校区</el-button>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							校区名称
						</div>
						<el-input v-model="school_name" placeholder="请输入校区名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addVisible = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="addCampus">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑校区 -->
			<el-dialog title="编辑校区" :visible.sync="editCampusShow" width="26%" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal" style="margin-left: 15px;">
							添加
						</div>
						<el-button @click="addSchools">添加校区</el-button>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							名称
						</div>
						<el-input v-model="edit_school_name" placeholder="请输入名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editCampusShow = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="editCampus">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import base from '../../../api/base.js';
export default {
	components: {
		index
	},
	data() {
		return {
			editCampusShow:false,
			editVisible: false,
			addVisible: false,
			school_nature: '1',
			school_intro: '',
			imageUrl: '',
			image:'',
			schoolName: '',
			schools: [],
			school_name: '',
			info:'',
			action:'/common/upload',
			headers:{
				token:window.localStorage.getItem("token")
			},
			edit_school_name:'',
			CampusId:''
		};
	},
	mounted() {
		this.$dragging.$on('dragged', ({ value }) => {
			console.log(value.item);
			console.log(value.list);
		});
		this.getSchoolInfo()
		this.schoolCampusIndex()
	},
	methods: {
		handleAvatarSuccess(res, file) {
			if(res.code==1){
				this.imageUrl = res.data.fullurl;
				this.image = res.data.url;
			}else{
				this.$message.error(res.msg)
			}
			
		},
		beforeAvatarUpload(file) {
			console.log(file);
		},
		handleClose(done) {
			done();
		},
		addSchools() {
			let arr = {
				schoolName: ''
			};
			this.schools = this.schools.concat(arr);
		},
		delSchool(i) {
			this.schools.splice(i, 1);
		},
		// 编辑弹窗
		editShow(item){
			this.CampusId = item.id;
			this.edit_school_name = item.name;
			this.editCampusShow = true;
		},
		// 获取学校信息
		getSchoolInfo(){
			this.$api.setting.schoolInfo().then(res=>{
				if(res.data.code==1){
					let data = res.data.data;
					this.info = data;
					this.school_nature = data.school_nature;
					this.school_intro = data.school_intro;
					this.imageUrl = base.imgUrl + data.school_badge;
					this.image = data.school_badge;
					this.schoolName = data.school_name;
				}
			})
		},
		// 修改学校信息
		edit_school(){
			let data = {
				school_name:this.schoolName,
				school_nature:this.school_nature,
				school_intro:this.school_intro,
				school_badge:this.image
			}
			if(this.schoolName==''||!this.schoolName){return this.$message.error('请输入学校名称')}
			if(this.school_intro==''||!this.school_intro){return this.$message.error('请输入学校简介')}
			if(this.image==''||!this.image){return this.$message.error('请上传学校校徽')}
			this.$api.setting.editSchool(data).then(res=>{
				if(res.data.code==1){
					this.editVisible = false;
					this.getSchoolInfo()
					this.$message.success('修改成功')
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 校区列表
		schoolCampusIndex(){
			this.$api.setting.schoolCampusIndex().then(res=>{
				if(res.data.code==1){
					this.schools = res.data.data.rows;
				}
			})
		},
		// 添加校区
		addCampus(){
			if(this.school_name==''||!this.school_name){return this.$message.error('请输入校区名称')}
			this.$api.setting.addCampus({
				name:this.school_name
			}).then(res=>{
				if(res.data.code==1){
					this.addVisible = false;
					this.schoolCampusIndex()
					this.$message.success('添加成功')
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		// 编辑校区
		editCampus(){
			if(this.edit_school_name==''||!this.edit_school_name){return this.$message.error('请输入校区名称')}
			this.$api.setting.editCampus({
				id:this.CampusId,
				name:this.edit_school_name
			}).then(res=>{
				if(res.data.code==1){
					this.editCampusShow = false;
					this.schoolCampusIndex()
					this.$message.success('编辑成功')
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		// 删除校区
		delCampus(id){
			this.$confirm('确定要删除？','提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(()=>{
				this.$api.setting.delCampus({
					id:id
				}).then(res=>{
					if (res.data.code == 1) {
						this.$message.success('删除成功');
						this.schoolCampusIndex();
					} else {
						this.$message.error(res.data.msg);
					}
				})
			})
		}
	}
};
</script>

<style lang="scss">
#school {
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 15px;
		.school-info {
			border-right: 1px solid #f0f2f5;
			.info-title {
				// background-color: #e4edff;
				padding: 15px 40px 0 0;
			}
			.infos {
				padding:0 40px 30px 40px;
				.school-name {
					font-weight: bold;
					font-size: 0.8333vw;
					padding: 10px 0;
				}
				.school-type {
					font-size: 0.625vw;
					background-color: #e4f1ff;
					color: #2d8cf0;
					display: inline-block;
					padding: 3px 10px;
					margin-top: 5px;
				}
				.school-brief {
					background-color: #f2f4f8;
					padding: 15px 20px;
					margin-top: 30px;
					font-size: 15px;
				}
			}
		}
		.branch-school {
			.branch-school-title {
				background-color: #e4edff;
				padding: 15px 40px;
			}
			.branch-school-list {
				padding: 30px 40px;
				display: flex;
				flex-wrap: wrap;
				.branch-school-item {
					border: 1px solid #eeeeee;
					background-color: #FFFFFF;
					width: 30%;
					margin-right: 20px;
					margin-bottom: 20px;
					border-radius: 3px;
					.cont {
						height: 13vh;
						padding: 0 30px;
						.section {
							font-size: 0.625vw;
							background-color: #e4f1ff;
							color: #2d8cf0;
							display: inline-block;
							padding: 3px 10px;
						}
						.el-icon-s-flag {
							color: #2d8cf0;
							margin-right: 3px;
						}
					}
					.meun {
						padding: 10px 0;
						background-color: #fafafa;
						color: #8d9da6;
						div {
							border-right: 1px solid #e8eaec;
							cursor: pointer;
							i {
								margin-right: 3px;
							}
						}
						div:last-child {
							border-right: none;
						}
						.meun-item:hover {
							color: #ed6335;
						}
					}
				}
				div:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 60px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
